<template>
    <div>
        <main-layout>
            <div class="row layout-spacing">
                <div class="col mb-3 ">
                    <div class="widget">
                        <div class="widget-content text-center">
                            <p>Balance</p>
                            <h4>{{ formatNumberToCurrency(balance) }}</h4>
                            <button class="btn btn-warning" data-target="#topupSection" data-toggle="collapse">Topup</button>
                            <form @submit.prevent="topupWallet">
                                <div class="collapse" id="topupSection">
                                    <div class="card card-body">
                                        <div class="form-group">
                                            <label for="Amount">Amount to topup</label>
                                            <input type="number" name="amount" id="topupAmount" class="form-control" min="100" v-model="topupAmount" required>
                                        </div>
                                        <div class="card-footer">
                                            <button class="btn btn-success float-right">Pay now</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="widget mt-3">
                        <div class="widget-content text-center">
                            <p>Your Subscription</p>
                            <div v-if="customerSubscription == null">
                                <p>You do not have any active subscription</p>
                            </div>
                            <div v-else>
                                <p>
                                    Plan: {{ customerSubscription.subscription.name }}
                                </p>
                                <p>
                                    Orders left: {{ customerSubscription.order_limit - customerSubscription.order_count }}
                                </p>
                            </div>
                            <div class="mt-3">
                                <router-link to="/packages" class="text-warning">View plans</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="widget widget-table-one">
                        <div class="widget-heading">
                            <h5 class="">Transaction History</h5>
                        </div>

                        <div class="widget-content">
                            <div class="transactions-list" v-for="t in transactions" :key="t.transaction.transactionID">
                                <div class="t-item">
                                    <div class="t-company-name">
                                        <div class="t-icon">
                                            <div class="icon">
                                                <i class="fa fa-wallet fa-fw"></i>
                                            </div>
                                        </div>
                                        <div class="t-name">
                                            <h4>{{ t.for }}</h4>
                                            <h5>{{ formatNumberToCurrency(t.amount) }}</h5>
                                            <p class="meta-date">{{ dayjs(t.created_at).format("MMMM D, YYYY h:mm A") }}</p>
                                        </div>

                                    </div>
                                    <div class="t-rate rate-dec">
                                        <p class="badge badge-pill badge-success text-white" v-if="t.type == 'credit'"><i class="fa fa-arrow-up"></i></p>
                                        <p class="badge badge-pill badge-danger text-white" v-if="t.type == 'debit'"><i class="fa fa-arrow-down"></i></p>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="widget-footer">
                                <simple-pagination :pagination="transactionsPagination" :callback="getTransactions"></simple-pagination>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </main-layout>
    </div>
</template>
<script>
import { useToast } from 'vue-toastification'
import appconfig from '../../appconfig'
import Auth from '../../helpers/auth'
import MainLayout from '../../layouts/MainLayout.vue'
import instance from '../../presenter/api/network'
import { loadScripts } from '../../utils'
import dayjs from "dayjs";
import SimplePagination from '../../components/SimplePagination.vue';
export default {
    components: {MainLayout, SimplePagination},
    data(){
        return {
            user_id: Auth.user().id,
            balance: 0,
            topupAmount: "",
            paymentGateway: "paystack",
            transactions: "",
            transactionsPagination: null,
            dayjs: dayjs,
            customerSubscription: null
        }
    },
    methods: {
        getBalance(){
            this.$root.startRequestLoader();
            instance.get("customer/wallet-balance", {params: {user_id: this.user_id}}).then(res => {
                this.balance = res.data.data;
            }).catch(err => {
                console.log("Can't get balance at time, please refresh page");
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        getTransactions(page=1){
            this.$root.startRequestLoader();
            let data = {
                page
            }
            instance.get("customer/wallet-information", {params: data}).then(res => {
                this.transactions = res.data.data.history;
                this.transactionsPagination = res.data.extra_data;
            }).finally(() => {
                this.$root.endRequestLoader()
            })
        },
        getSubscriptionInfo(){
            this.$root.startRequestLoader();
            instance.get("customer/user-subscription-information").then(res => {
                this.customerSubscription = res.data.data.current_subscription_information;
            }).catch(err => {

            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        async topupWallet(){
            //connect to payment service provider
            this.$root.startRequestLoader();
            try{
                let fundRequestData = {amount: this.topupAmount, user_id: this.user_id}
                let fundRequestResponse = await instance.post("customer/fund-wallet", fundRequestData);
                
                this.$root.endRequestLoader();
                let that = this;
                var handler = PaystackPop.setup({
                    key: appconfig.paystackPublicKey,
                    email: Auth.user().email,
                    amount: parseInt(this.topupAmount) * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
                    currency: 'NGN', // Use GHS for Ghana Cedis or USD for US Dollars
                    async callback (response){
                    //this happens after the payment is completed successfully
                        that.$root.startRequestLoader();
                        var reference = response.reference;
                        try {
                            const confirmationData = {
                                transactionID: fundRequestResponse.data.data.transactionID,
                                payment_reference: reference,
                                payment_gateway: that.paymentGateway
                            }
                            const confirmFund = await instance.post("customer/confirm-fund-wallet", confirmationData);

                            that.$root.endRequestLoader();
                            that.balance = confirmFund.data.data.balance;
                            useToast().success("Your wallet has been credited successfuly");
                        } catch (error) {
                            that.$root.endRequestLoader();
                            useToast().error("Unable to complete verify your transaction");
                        }
                    },
                    onClose: function() {
                        alert('Transaction was not completed, window closed.');
                    },
                });
                handler.openIframe();
            }catch(error){
                console.error(error)
                this.$root.endRequestLoader();
                useToast().error("You are unable to credit your wallet at this time, please try again later");
            }
        }
    },
    mounted(){
        this.getBalance();
        this.getTransactions();
        this.getSubscriptionInfo();
        loadScripts([
            "https://js.paystack.co/v1/inline.js"
        ])
    }
}
</script>